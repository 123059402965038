<template>
    <div class="sAgreement">
        <el-container>
            <el-header style="padding: 0" height="100%">
              <vheader class="hidden-sm-and-down"></vheader>
              <ivheadera class="hidden-md-and-up"></ivheadera>
            </el-header>
            <el-main style="margin-top: 72px;text-align: left">
              <div class="w">
                <p style="font-size:13.5pt; line-height:115%; margin:30pt 0pt 10pt 0pt; text-align:center"><span style="font-family:微软雅黑; font-size:13.5pt; font-weight:bold">趣活动服务协议</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">1.服务协议的确认和接纳</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">趣活动是由深圳市国际交流合作基金会拥有所有权与经营权产品。用户在接受趣活动的服务之前，请务必仔细阅读本条款并同意本声明。访问者访问本网站或通过各类方式使用本网站的行为，都将被视为是对本声明全部内容的无异议的认可。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt; text-indent:0pt"><span style="font-family:微软雅黑; font-size:12pt">1.1</span><span style="font:7.0pt 'Times New Roman'">&#xa0; </span><span style="font-family:微软雅黑; font-size:12pt">用户直接或间接通过各类方式</span><span style="font-family:微软雅黑; font-size:12pt">产生与本网站相关的行为时，都将被视为已无条件接受本声明所涉及的全部内容；若用户对本声明的任何条款有异议，请立即停止使用趣活动所提供的全部服务。 否则视为你已接受趣活动的全部条款。</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1.2 当用户使用趣活动的服务时，需遵守中国的法律法规并保证信息的真实性，同时应当恪守以下承诺： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1)趣活动在修改条款时不再另行通知用户，用户可随时通过趣活动网站浏览最新服务协议条款。用户只有在同意遵守相关法律法规和本协议时，才有权使用趣活动的服务，无论用户是否有意访问或使用此服务等。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2)如果用户不同意趣活动对协议相关条款的修改，则有权停止使用服务。如果继续使用服务，则视为已接受趣活动最新服务协议条款。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3)用户注册时请按照提示填写正确的手机号、邮箱、姓名和密码，并确保日后更新的相关资料具有有效性和合法性。若用户提交任何违法或者趣活动认为不适合在其平台上展示的资料，趣活动则有理由怀疑用户的资料属于恶意操作，并且有权立即暂停或终止用户的账号。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4)趣活动仅对用户的信息与资料进行形式审查，不进行实质性审查（实质性审查包括但不限于鉴别、核实任何登记资料的真实性、正确性以及是否为最新资料的审查）对此所产生的任何纠纷趣活动不承担过错责任。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5)用户有保证其信息和资料详尽、准确并对其及进行时更改的义务。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">6)用户有义务在其注册信息发生变动时，及时更新相关资料以保证在使用该信息时的准确性和合法性。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">2.用户使用规则</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2.1 用户有义务保管好个人账号的相关信息并对以其进行的所有行为承担法律责任。用户不应将其账号、密码转让或借予他人使用。因黑客行为或用户的保管疏忽等自身原因导致账号、密码外泄，趣活动不承担任何法律责任。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2.2 用户在使用趣活动的服务时，须向趣活动提供真实资料并及时更新。趣活动会根据用户的权限设置对用户资料的信息进行保密。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2.3 用户同意接受趣活动通过电子邮件、短信或其他方式向用户发送活动通知及其相关信息。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2.4 根据有关法律法规，趣活动在此郑重提醒用户：任何经由本服务发布的文字、图片信息，无论是公开还是私密发布，均需由趣活动工作人员进行审核。趣活动仅为用户提供活动发布平台，无法控制经由本服务发布的活动现场情况，因此不保证活动是否符合用户的预期。但趣活动有权依法停止发布任何不符合平台规则及违反法律法规等内容并采取相应措施，包括但不限于暂停用户使用本服务，保存有关记录，并有权向有关机构报告。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2.5 用户保证对其通过趣活动服务发布到网站上的内容拥有完整的合法的知识产权，保证趣活动使用该内容不违反国家的法律法规，也不侵犯第三方的合法权益或承担任何义务。用户承诺上传的内容不涉及侵犯他人著作权、商标、名誉、隐私及其他一切违法内容。用户应对其所提供内容因形式、内容及授权的不完善、不合法所造成的一切后果承担全部法律责任。 如因此给趣活动造成损失，趣活动有权追究相关的人员的赔偿责任包括但不限于赔偿相关的律师费、交通费、诉讼费、保全费等）。</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2.6 用户承诺遵循中华人民共和国有关互联网使用的法律法规，不从事洗钱、诈骗、以及其他任何破坏社会主义经济秩序的活动；用户使用本服务的行为若有任何违反国家法律法规或侵犯第三方的合法权益时，趣活动将保留向公安机关以及国家有关监管部门举报的权利。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2.7 用户在使用趣活动服务期间，本协议将保持完整的效力。如果用户所发布的活动与法律相抵触或者不符合本协议，趣活动可以在不发出任何通知的情况下，随时终止用户的注册用户资格。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">3.隐私保护</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3.1 用户注册趣活动账号时，系统会要求其提供个人信息：如姓名、电话、邮箱以及账号密码。</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3.2 趣活动在用户使用其服务时可能获取的信息： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1)Cookie信息：当用户使用趣活动的服务时，系统会将一个或多个Cookie（包含一串字符的小型档案）传送到用户的计算机或其他装置上，以便储存用户的偏好设定，从而提供更好的服务。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2)日志信息：当用户使用趣活动的服务时，其服务器会自动记录用户浏览器所传送的信息。服务器日志包含用户的网页请求、因特网通讯协议地址、浏览器类型、浏览器语言、传送请求的日期和时间，以及一个或多个用来识别用户浏览器的Cookie等信息。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3)位置信息：趣活动提供基</span><span style="font-family:微软雅黑; font-size:12pt">于</span><span style="font-family:微软雅黑; font-size:12pt">百度地图</span><span style="font-family:微软雅黑; font-size:12pt">开放API的定位服务，无论用户使用哪种设备使用此服务， 趣活动皆会主动询问用户是否要提供地理位置相关信息：包含用户的实际位置信息（例如移动装置传送的GPS讯号）以及可用来判断大概位置的信息（例如基地台编号） </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4)链接信息：趣活动可能会以特定格式显示链接，以此改善定制化内容和推荐的质量以及追踪用户是否可以访问这些链接。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5)用户向趣活动联盟网站提供的信息：趣活动会通过其他网站提供某些服务。用户提供给这些网站的个人信息可能会传送给趣活动，趣活动会根据本《隐私权政策》处理此类信息。每个网站所采用的隐私权政策各不相同，建议用户详阅他们的隐私权政策。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3.3 用户的个人信息将在下述情况下部分或全部被披露： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1)经用户同意，向第三方披露； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2)根据有关法律规定，或应行政、司法机构的要求，向第三方或者行政、司法机构披露； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3)如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4)为提供用户所要求的商品和服务，而必须和第三方分享用户的个人信息； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5)其他趣活动根据法律或者网站政策认为必要的披露。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3.4 请用户妥善保管账户及密码信息。如果用户发现个人信息泄密，尤其是趣活动的账户和密码泄露，请立即联络趣活动客服，以便趣活动立即采取相应措施。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">4.服务内容介绍</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4.1 网络信息服务：指活动主办方根据本协议的规定使用趣活动发布活动信息、作为主办方和参与者确认票种交易信息、用户参加趣活动的有关活动以及其他由趣活动同意提供的信息服务。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4.2 趣活动平台群发信息服务：指趣活动平台为商业提供的活动推广和通知邀请等服务。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">5.知识产权</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5.1 趣活动提供的服务中所包含的任何文字、图片资料均受相关知识产权法律的保护，未经相关权利人同意，上述资料均不得在任何渠道改写或发布。趣活动不对上述资料产生过程中的不准确予以赔偿并负责。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5.2 本服务的相关软件含有受法律保护的专有保密资料。未经趣活动或广告商明示授权，用户不得修改、兜售、散布本服务或软件的任何部分。用户同意只通过由趣活动所提供的界面而非任何其它途径使用本服务。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5.3 用户在趣活动发布的内容应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益，用户同意授予趣活动所有上述内容在全球范围内的免费、不可撤销的的使用权许可，据该许可趣活动将有权以展示、推广及其他不为我国法律所禁止的方式使用前述内容。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">6.禁止发布的内容</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">6.1 请用户谨慎选择发布在趣活动平台的信息，根据相关法律法规，任何组织和个人都不得利用趣活动发布以下内容： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1)反对宪法所确定的基本原则的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3)损害国家荣誉和利益的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4)煽动民族仇恨、民族歧视，破坏民族团结的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5)破坏国家宗教政策，宣扬邪教和封建迷信的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">6)散布谣言，扰乱社会秩序，破坏社会稳定的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">8)侮辱或者诽谤他人，侵害他人合法权益的； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">9)含有法律、行政法规禁止的其他内容的信息。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">如果用户知道任何人违反上述规定，请直接联系趣活动客服。趣活动有权直接删除包含以上内容的信息、图片、文字等，并不对任何用户提供的资料承担法律责任。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">6.2 除上述一般条款的规定，如下列出了部分在趣活动发布后将被设为私有的活动类型： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1)批量重复发布同一活动，重复的活动将被设置为私有状态； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2)明显销售性质、虚假宣传、招商（生）、征集项目、留学（投资）咨询、销售产品、线上活动、展览、相亲（恋爱交友）、医疗、美容、众筹、宗教类等等其他类型暂时都会被设置为私有状态； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3)无具体活动内容的英语培训、相亲交友、演讲口才等营销类活动将被设置为私有状态； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4)展会招商类广告等，都将被设置为私有状态。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">7.风险与免责</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">用户通过本网站与广告商或其他第三方进行任何形式的通讯或商业往来，或参与活动，包含相关服务的付款，以及达成的其它任何条款，完全是用户与广告商或其他第三方之间之的行为。用户因前述任何交易或前述广告商及其他第三方而遭受的任何性质的损失或损害都与趣活动无关，趣活动对此不承担任何法律责任。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">基于互联网的开放性和社交网络服务的传播特殊性，趣活动特别提醒用户谨慎注意以下风险： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1)本服务仅提供在线活动管理平台，用户应当对其他用户使用本服务所发布的内容进行独立判断并承担相应风险，用户了解并确认，趣活动不对本服务用户之间的纠纷承担任何责任。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2)用户在使用本服务过程中所发布的内容有可能会被第三方修改或做其他用途，脱离用户的预期和控制，用户必须充分意识此类风险的存在，趣活动对由此产生的纠纷不予负责。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3)请用户切勿在使用本服务时透露任何涉及财产、本服务账号及密码等重要信息，否则，由此带来的损失由用户自行承担。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">7.3</span><span style="color:#0000ff; font-family:微软雅黑; font-size:12pt"> </span><span style="font-family:微软雅黑; font-size:12pt">深圳市国际交流合作基金会不担保趣活动提供给用户的全部信息或从趣活动发出的信息没有病毒或其他有害成分。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">7.4 以下情况使您无法使用各项服务时，本公司不承担任何损害赔偿责任，该状况包括但不限于： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1)由于用户将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因趣活动原因导致的个人信息的泄漏； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2)任何由于政府管制而造成的暂时性网站关闭； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3)因不可抗力导致的任何后果； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">4)因本公司公告之后系统停机维护期间； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">5)因黑客攻击、电信部门技术调整或故障、网站升级方面的问题等原因而造成的服务中断或者延迟。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">7.5 本协议适用中华人民共和国的法律，所有的争端将诉诸深圳市国际交流合作基金会所在地的人民法院。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">8.协议变更、服务变更、中断或终止</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">8.1 本服务条款可由趣活动根据需要随时更新，且毋须另行通知。如果用户不同意相关变更，必须停止使用“服务”。登录或继续使用“服务”将表示用户接受经修订的协议。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">8.2 鉴于服务的特殊性，用户同意趣活动有权随时变更、中断或终止服务。其变更、中断或终止的服务属于免费服务，趣活动无需通知用户，也无需对任何用户或任何第三方承担任何责任。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">8.3 用户理解趣活动需要定期或不定期地对提供服务的平台或相关设备进行维护，如因此类情况而造成收费服务在合理时间内的中断，趣活动无需为此承担任何责任，趣活动会尽可能事先进行通告。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">8.4 如发生下列任何一种情形，趣活动有权随时中断或终止向用户提供本协议项下的服务且无需对用户或任何第三方承担任何责任： </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">1）用户提供的个人资料不真实； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">2）用户违反本协议中规定的使用规则； </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">3）用户违反法律、行政法规的规定；</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">8.5 用户同意趣活动基于自行考虑，因任何理由，包含但不限于缺乏使用，或认为用户已经违反本使用协议，终止用户的账号或本服务的使用，并将用户在本服务内任何内容加以移除。用户同意依本使用协议提供的服务，无需进行事先通知即可中断或终止。趣活动对用户或任何第三人均不承担任何责任。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">9.适用的法律和管辖权</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">9.1 用户同意遵守中华人民共和国相关法律法规的所有规定，并对其在使用本服务过程中产生的结果承担全部责任。同时如果趣活动有理由认为用户有违反国家法律法规行为，趣活动可在任何时候不经事先通知终止向用户提供服务。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">9.2 用户因本协议产生的争议，均应依照中华人民共和国法律予以处理，并由深圳市国际交流合作基金会所在地的人民法院提起诉讼。  </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt; font-weight:bold">10.其他</span><span style="font-family:微软雅黑; font-size:12pt"> </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">10.1 若用户对本声明的任何条款有异议，请停止使用趣活动所提供的全部服务。趣活动修改条款时不再另行通知用户，如果用户继续使用服务，则视为用户接受趣活动最新服务协议条款。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">10.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">10.3 若用户和趣活动之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，用户同意将纠纷或争议提交深圳市国际交流合作基金会所在地的人民法院管辖。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">10.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">10.5 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。 </span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
                <p style="font-size:12pt; line-height:115%; margin:0pt 0pt 10pt"><span style="font-family:微软雅黑; font-size:12pt">10.6 本服务协议的最终解释权归深圳市国际交流合作基金会所有。 </span></p>

              </div>
            </el-main>
            <el-footer>
              <vfooter class="hidden-sm-and-down"></vfooter>
              <ivfooter class="hidden-md-and-up"></ivfooter>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="less">
</style>
